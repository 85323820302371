import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import 'antd/dist/antd.less'

import useBoolean from '@vezham/ui/dist/contracts/hooks/useBoolean'
import useVStore from '@vezham/ui/dist/contracts/hooks/useVStore'
import {
  HQkeys,
  StateKeys,
  StoreKeys,
  TokenKeys
} from '@vezham/ui/dist/contracts/iam'
import { useTheme } from '@vezham/ui/dist/contracts/theme'
import { Vcolor, Vmode } from '@vezham/ui/dist/contracts/theme/interface'
import '@vezham/ui/dist/index.css'
import Loading from '@vezham/ui/dist/organisms/loading'

import { business as useBusiness } from '@store/hq/business/actions'
import { people as usePeople } from '@store/hq/people/actions'
import usePeopleVStore from '@store/hq/people/vstore'

import '@/App.less'
import { AppRoutes, Routers } from '@/routes'

function App() {
  const searchParams = new URLSearchParams(window.location.search)
  const state = searchParams.get(StateKeys.KEY)
  let init_iam_account_by_code = searchParams.get(
    StateKeys.INIT_IAM_ACCOUNT_BY_CODE
  )

  const navigate = useNavigate()
  const { value: isLoading, setValue } = useBoolean(true)
  const { setMode, setColor } = useTheme()

  const goToAccounts = (iam?: boolean) => {
    let param_iam = iam
      ? `&${StateKeys.KEY}=${StateKeys.NO_ACTIVE_IAM_ACCOUNT}`
      : ''

    window.open(
      process.env.REACT_APP_ACCOUNTS_URL +
        `?${StoreKeys.REDIRECTED_FROM}=business${param_iam}`,
      '_self'
    )
  }

  const init = async () => {
    let RTaccount = useVStore.get(TokenKeys.ACCOUNT_REFRESH_TOKEN)
    if (
      state == StateKeys.INIT_IAM_ACCOUNT_BY_CODE &&
      init_iam_account_by_code
    ) {
      if (!RTaccount) {
        usePeopleVStore.initByCode(init_iam_account_by_code)
        navigate(AppRoutes.LAUNCHER_IAM)
        setValue(false)
        return
      }
    }

    if (RTaccount) {
      await usePeople
        .profile({})
        .then(async () => {
          const RTbusiness = useVStore.get(TokenKeys.BUSINESS_REFRESH_TOKEN)
          if (RTbusiness) {
            await useBusiness
              .profile({})
              .then(business => {
                useVStore.set(HQkeys.ACTIVE_BUSINESS, business.handle)

                if (state == StateKeys.NO_ACTIVE_IAM_BUSINESS) {
                  handleNoActiveIAMbusiness(RTaccount, RTbusiness)
                } else {
                  setValue(false)
                }
                return
              })
              .catch(err => {
                console.log('business | err: ' + err)
                // useVStore.delete(TokenKeys.BUSINESS_REFRESH_TOKEN)
                navigate(AppRoutes.LogIn + `?state=no_active_business`)
                return
              })
          } else {
            navigate(AppRoutes.Welcome)
            setValue(false)
            return
          }
        })
        .catch(() => {
          goToAccounts()
          return
        })
    } else {
      goToAccounts(true)
      return
    }
  }

  const handleNoActiveIAMbusiness = (account: string, business: string) => {
    window.open(
      process.env.REACT_APP_HQ_URL +
        `?${StateKeys.KEY}=${StateKeys.INIT_IAM_BUSINESS_BY_CODE}&${StateKeys.INIT_IAM_ACCOUNT_BY_CODE}=${account}&${StateKeys.INIT_IAM_BUSINESS_BY_CODE}=${business}`,
      '_self'
    )
  }

  useEffect(() => {
    init()
  }, [])

  // wjdlz | TODO: have to update it based on API response
  useEffect(() => {
    setMode(Vmode.SYSTEM)
    setColor(Vcolor.BLUE)
  }, [])

  return <>{isLoading ? <Loading /> : <Routers />}</>
}

export default App
